.start-page__title,
.start-page__content {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  color: #00000089;
}

.start-page__title {
  align-items: center;
  justify-content: center;
  
  display: flex;

  height: 10rem;

  font-size: 2rem;
}

.start-page__content {
  flex-direction: column;
  align-items: center;
  justify-content: center;

  display: flex;
}

.start-page__line {
  margin-bottom: 1rem;
}

.start-page__seperator {
  margin-top: 3rem;
  border-top-color: #00000089;
  border-top-width: 0.2rem;
  border-top-style: solid;
}

.start-page__note {
  flex-direction: column;
  align-items: center;
  justify-content: center;

  display: flex;
  width: 60%;
  line-height: 2rem;
}

@media only screen and (max-width: 980px) {
  .start-page__title,
  .start-page__content {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .start-page__title {
    height: 16rem;

    font-size: 3rem;
  }

  .start-page__content {
    font-size: 2rem;
  }

  .start-page__line {
    margin-bottom: 2rem;
  }

  .start-page__note {
    width: 95%;
    line-height: 3rem;
  }
}