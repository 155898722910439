.object-image {
  position: absolute;
  top:10%;
  left:10%;

  display: none;

  overflow: hidden;

  box-sizing: border-box;
  border: 0.2rem solid black;
  width: 80%;
  height: 80%;

  background: rgba(0, 0, 0, 0.9);
}

.object-image__img {
  position: absolute;
}

.object-image__desc {
  position: absolute;

  width: 100%;
  height: 2rem;

  background: rgba(0, 0, 0, 0.8);

  color: white;
}

.object-image__desc-header {
  position: absolute;

  align-items: center;
  justify-content: center;
  
  display: flex;

  width: 6rem;
  height: 100%;

  font-weight: bold;

}

.object-image__desc-content {
  position: absolute;

  align-items: center;
  justify-content: center;
  
  display: flex;

  width: 100%;
  height: 100%;

  text-align: center;
}

.object-image__close {
  position: absolute;
  left: calc(100% - 6rem);
  top: 3rem;

  width: 5rem;
  height: 5rem;

  cursor: pointer;
}

.object-image__svg-close-icon {
  fill:white
}

.object-image__svg-arrow-icon {
  fill: none;
  stroke: white;
  stroke-width: 0.2rem;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.object-image__left {
  position: absolute;
  left: 0;
  top: calc(50% - 4rem);

  width: 3rem;
  height: 8rem;

  cursor: pointer;
}

.object-image__right {
  position: absolute;
  left: calc(100% - 3rem);
  top: calc(50% - 4rem);

  width: 3rem;
  height: 8rem;

  cursor: pointer;
}

.object-tooltip {
  position: absolute;
  top: 0px;
  left: 0px;

  grid-template-rows: 25% auto;
  
  display: none;

  box-sizing: border-box;
  border-radius: 0.3rem;
  border: 1px solid black;
  width: 12rem;
  height: 6rem;

  background-color: rgba(255, 255, 255, 0.97);
}

.object-tooltip__header {
  justify-content: center;
  align-items: center;
  
  display: flex;

  border-bottom: 1px solid black;

  background-color: rgba(231, 230, 230, 0.97);
}

.object-tooltip__content {
  justify-content: center;
  align-items: center;

  display: flex;

  text-align: center;
}

.info {
  position: absolute;
  top: 5rem;
  left: 2rem;
  justify-content: center;
  align-items: center;

  display: flex;

  box-sizing: border-box;
  border-radius: 0.3rem;
  border: 1px solid #00000089;
  width: 23rem;
  height: 6rem;

  background-color: #f5f7f7;
  cursor: pointer;

  font-size: 0.7rem;
  color: #00000089;
  font-family: Arial, Helvetica, sans-serif;
}

.info__button {
  position: absolute;
  top: 5rem;
  left: 2rem;
  justify-content: center;
  align-items: center;
  
  display: none;

  box-sizing: border-box;
  border-radius: 1rem;
  border: 1px solid #00000089;
  width: 1rem;
  height: 1rem;

  background-color: #f5f7f7;
  cursor: pointer;

  font-size: 0.8rem;
  font-weight: bold;
  color: #00000089;
}

.info__mobile {
  display: none;
}

.info__pc {
  display: block;
}

@media only screen and (max-width: 980px) {
  .info-button {
    top: 8rem;

    width: 3rem;
    height: 3rem;

    font-size: 1.5rem;
  }

  .info {
    top: 8rem;

    width: 30rem;
    height: 6rem;

    font-size: 1rem;
  }

  .mobile {
    display: block;
  }

  .pc {
    display: none;
  }

  .object-image-desc {
    height: 4rem;
  }

  .object-image-desc-header,
  .object-image-desc-content {
    font-size: 2rem;
  }

  .object-image-close {
    top: 6rem;
    left: calc(100% - 7rem);

    width: 6rem;
    height: 6rem;
  }

}