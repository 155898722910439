.tab-buttons {
  align-items: center;
  
  display: flex;

  background-color: #f5f7f7;

  height: 3rem;
}

.tab-content {
  height: calc(100% - 3rem);
}

.tab-button {
  justify-content: center;
  align-items: center;

  display: flex;

  background-color: inherit;
  cursor: pointer;

  height: 100%;
  width: 10rem;

  font-family: Arial, Helvetica, sans-serif;
  color: #b5bbcc;
  font-size: 1.1rem;
}

.tab-button,
.tab-button:focus
.tab-button:active {
  border: none;
	outline:none;
	padding:0;

	background:none;
}

.tab-button.active {
  border-bottom: 2px solid #00000089;

  font-weight: bold;
  color: #00000089;
}

@media only screen and (max-width: 980px) {
  .tab-buttons {
    height: 6rem;
  }

  .tab-button {
    width: 15rem;

    font-size: 2rem;
  }

  .tab-content {
    height: calc(100% - 6rem);
  }
}