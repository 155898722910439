svg {
  width: 100%;
  height: 99%;

  font-family: Arial, Helvetica, sans-serif;
}

.wall {
  fill:none;
  stroke:#00000089;
}
.cross {
  stroke:#000000;
  stroke-width:1.4;
}
.track {
  fill:none;
  stroke-width:3;
  stroke:#00000089;
}
.object-label {
  fill:#73d216aa;
  text-anchor:middle;
  font-weight:bold;
  font-size:8px;
  dominant-baseline:middle;
}
.other-label {
  fill:#00000089;
}
.field-label {
  fill:#ffd000;
  font-size:100px; 
}
.object {
  fill-opacity:0;
  stroke:#ff0000;
}