.contact-page__content {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  color: #00000089;
  margin: 3rem;
}

.contact-page__line {
  margin-bottom: 1rem;
}


@media only screen and (max-width: 980px) {
  .contact-page__content {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .contact-page__content {
    font-size: 2rem;
  }

  .contact-page__line {
    margin-bottom: 2rem;
  }
}